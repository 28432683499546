/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* body {
  background-color: #544741;
  background: linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white),
              linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white),
              linear-gradient(45deg, gray 25%, transparent 25%, transparent 75%, gray 75%, gray),
              linear-gradient(45deg, gray 25%, transparent 25%, transparent 75%, gray 75%, gray);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px, 5px 5px, 0 0;
  font-family: 'Futura' !important;
} */


html {
  height: 100%;
}

body {
  /* background: linear-gradient(45deg, white 50%, rgb(242, 242, 242) 50%); */
  /* background-image: repeating-linear-gradient(
    135deg, 
    rgb(255, 255, 255), 
    rgb(255, 255, 255) 8px, 
    #ead8c4 8px, 
    #ead8c4 12px,
    rgb(255, 255, 255) 12px, 
    rgb(255, 255, 255) 20px, 
    #e9e9bc 20px,
    #e9e9bc 24px,
    rgb(255, 255, 255) 24px, 
    rgb(255, 255, 255) 32px, 
    #a0dfbe 32px,
    #a0dfbe 36px
    ); */
    /* background-image: repeating-linear-gradient(
    135deg, 
    #e9e9bc,
    #e9e9bc 10px,
    #ead8c4 10px, 
    #ead8c4 12px,
    #e9e9bc 12px,
    #e9e9bc 22px,
    #a0dfbe 22px,
    #a0dfbe 24px
    ); */
    background-image: repeating-linear-gradient(
    135deg, 
    #f6f6f6,
    #f6f6f6 10px,
    #f0f0f0 10px, 
    #f0f0f0 13px
    );
  /* background-size: 8px 50%; */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* p
{
  color: #544741 !important;
}

h1
{  
  color: #544741 !important;
}

h4
{  
  color: #544741 !important;
} */

.navbar-brand
{
     margin-left:20px !important;
}
.logo
{
  color: #f8931f !important;
  text-decoration: none !important;
}

.nav-link{
      color: #f8df22 !important;
      text-decoration: none !important;
}
a.dropdown-toggle {
  margin-left: 8px !important;
}
button.navbar-toggler {
  margin-right: 15px !important;
}
.dropdown-menu {
  background-color: #f0f0f0 !important;
}
.dropdown-item2 {
  color: #000000 !important;
  text-decoration: none !important;
}
.breadcrumb2 {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0px;
  text-decoration: none !important;
}
.breadcrumb {
  border: 0px !important;
  text-decoration: none !important;
  box-shadow: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.circle {
	width: 300px;
	height: 300px;
	background-color: #f8931f;
	border-radius: 50%;
  background-image: url('./assets/acrew-logo.png');
  background-size: cover;
}

.inner-circle {
  width: 150px;
  height: 150px;
  background-color: yellow;
  border-radius: 50%;
}

/* body {
  background-color: #f8df22 !important;
  font-family: 'Futura' !important;
} */

nav {
  background-color: #009345 !important;
  font-family: 'Futura-Bold' !important;
  color: #f8931f !important;
}
.navbar {
  background-color: #009345 !important;
  color: #f8931f !important;
  border-bottom: 1px solid;
  border-color: #544741
}
.navbar-light {
  background-color: #009345 !important;
  color: #f8931f !important;
}
.navbar-dark {
  background-color: #544741 !important;
  color: #f8931f !important;
}
.bg-dark {
  background-color: #f8931f !important;
  color: #f8931f !important;
}
.btn {
  background-color: #f8931f !important;
}

@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./assets/fonts/FuturaLT-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'Futura-Bold';
  src: local('Futura-Bold'), url(./assets/fonts/FuturaLT-Bold.ttf) format('truetype');
}